import { Lock, MagnifyingGlass, Power, UserCircle } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./dashboard.css";
import userDemo from "../../../assets/img/userdemo.svg";
import cardUser from "../../../assets/img/cardUser.svg";
import multiUser from "../../../assets/img/multiUser.svg";
import online from "../../../assets/img/online.svg";
import attach from "../../../assets/img/attach.svg";
import CountUp from "react-countup";

import EarningChart from "./EarningChart";
import OverViewChart from "./OverViewChart";
import { useHistory } from "react-router-dom";

import { Menu, MenuItem } from "@material-ui/core";
import { logout_func3 } from "../../Const/logoutCommon";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { PacmanLoader } from "react-spinners";
import PmLoader from "../../CommonDesgin/PmLoader";

const Dashboard = () => {
  const history = useHistory();
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(true);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const [userData, setUserData] = useState({});
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  useEffect(() => {
    if (localStorage.getItem("is_logged_in")) {
      history.push("/");
    } else {
      history.push("/login");
    }
  }, []);
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data);
        setDashboardData(res.data.data);
        // console.log("data", res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };
  const userProfile = (url) => {
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data, "profile");
        setUserData(res.data.data);
        localStorage.setItem("userProfile", JSON.stringify(res.data.data));
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };

  useEffect(() => {
    apiFunction(`${BASE_URL}api/v1/chat/admin/dashboard/`);
  }, []);
  useEffect(() => {
    userProfile(`${BASE_URL}api/v1/auth/profile/`);
  }, []);
  if (loading) {
    return <PmLoader />;
  }
  return (
    <>
      <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
        <div>
          <div className="upper ">
            {/* <div className="input" style={{opacity:'0'}}>
            <input type="text" placeholder="Search ..." />
            <button>
              <MagnifyingGlass color="white" size={28} weight="bold" />
            </button>
          </div> */}

            <div>
              <h5>{"Dashboard"}</h5>
              <p>{"Home / Dashboard"}</p>
            </div>

            <div
              className="upper_user"
              aria-controls={open2 ? "long-menu" : undefined}
              aria-expanded={open2 ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick2}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={userData?.image_url}
                alt=""
              />
            </div>
          </div>
          <>
            <Menu
              id="long-menu"
              className="pop_over3"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              PaperProps={{
                style: {
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  width: "22ch",
                  marginRight: "10ch",
                  top: "100px !important",
                  padding: "5px ",
                },
              }}
            >
              <MenuItem
                className="privacy my-2"
                onClick={() => {
                  history.push("/view-profile");
                  handleClose2();
                }}
              >
                {" "}
                <UserCircle
                  color="#1F5DA0"
                  size={24}
                  weight="bold"
                  className="me-3"
                />
                View Profile
              </MenuItem>
              <MenuItem
                className="privacy my-2"
                onClick={() => {
                  history.push("/change-password");
                  handleClose2();
                }}
              >
                {" "}
                <Lock
                  color="#1F5DA0"
                  size={24}
                  weight="bold"
                  className="me-3"
                />
                Change Password
              </MenuItem>
              <MenuItem
                className="privacy my-2"
                onClick={() => {
                  logout_func3(history);
                  // history.push('/login')
                  handleClose2();
                }}
              >
                {" "}
                <Power
                  color="#1F5DA0"
                  size={24}
                  weight="bold"
                  className="me-3"
                />
                Logout
              </MenuItem>
            </Menu>
          </>
        </div>

        <div className="row mt-0 mt-md-4">
          <div className="d-flex justify-content-between cards mb-4">
            <div className="data   px-4 py-3 d-flex justify-content-between align-items-center">
              <div className="">
                <p>Total Users </p>
                <h5>
                  <CountUp end={dashboardData?.total_user} duration={4} />
                </h5>
              </div>
              <div>
                <img src={cardUser} alt="" />
              </div>
            </div>
            <div className="data   px-4 py-3 d-flex justify-content-between align-items-center">
              <div>
                <p>Total Group</p>
                <h5>
                  {" "}
                  <CountUp end={dashboardData?.total_groups} duration={3} />
                </h5>
              </div>
              <div>
                <img src={multiUser} alt="" />
              </div>
            </div>
            <div className="data   px-4 py-3 d-flex justify-content-between align-items-center">
              <div>
                <p>Online Users</p>
                <h5>
                  {" "}
                  <CountUp end={dashboardData?.online_user} duration={4} />
                </h5>
              </div>
              <div>
                <img src={online} alt="" />
              </div>
            </div>
            <div className="data  px-4 py-3 d-flex justify-content-between align-items-center">
              <div>
                <p>Total Attachments</p>
                <h5>
                  {" "}
                  <CountUp end={dashboardData?.attachment_count} duration={5} />
                </h5>
              </div>
              <div>
                <img src={attach} alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <OverViewChart dashboardData={dashboardData} />
          </div>
          <div className="col-md-6 mb-3">
            <EarningChart dashboardData={dashboardData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
