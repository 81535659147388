import { showToast } from "../../utils/ToastHelper";
export const logout_func2 = (history) => {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("access_token");
  localStorage.removeItem("userData");
  showToast("success", "Invalid Token");

  history.push("/login");
};
export const logout_func3 = (history) => {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("access_token");
  localStorage.removeItem("userData");
  localStorage.removeItem("userProfile");
//   showToast("success", "Invalid Token");
  history.push("/login");
};
const data = JSON.parse(localStorage.getItem("userData"))
export const isStaff = data?.is_superuser