import { CaretLeft, CaretRight } from "phosphor-react";
import React from "react";

const PagginationOld = ({ prevUrl, nextUrl, pageCount, pageNumber,handlePaginationNext,handlePaginationPrevious,handleChangeCurrentPage ,total_item,page_show_start,this_page_items_show}) => {
  return (
    <div className="mt-4 d-flex justify-content-between align-items-center">
      <div style={{ color: "#1F5DA0", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold" }}>{page_show_start}- {this_page_items_show} </span>
        <span> Of {total_item}</span>
      </div>
      <div className="d-flex paggi">
        {prevUrl !== null ? (
          <div className="px-2 s-btn pointer" onClick={handlePaginationPrevious}>
            {" "}
            <CaretLeft size={21} weight="bold" />{" "}
          </div>
        ) : (
          <div className="px-2 s-btn">
            {" "}
            <CaretLeft size={21} weight="bold" />{" "}
          </div>
        )}
        {[...Array(pageCount === null ? 1 : pageCount)].map((elem, index) => (
          <div
          onClick={()=> handleChangeCurrentPage(index + 1 )}
            className={`px-3 s-btn pointer ${
              index + 1 === pageNumber ? "s-active" : ""
            }`}
          >
            {" "}
            {index + 1}{" "}
          </div>
        ))}
        {nextUrl !== null ? (
          <div onClick={handlePaginationNext} className="px-2 s-btn pointer">
            {" "}
            <CaretRight size={21} weight="bold" />
          </div>
        ) : (
          <div className="px-2 s-btn">
            {" "}
            <CaretRight size={21} weight="bold" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PagginationOld;
