import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiEdit2Line } from "react-icons/ri";
import { BiPencil } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";
import PageHeader from "../../CommonDesgin/PageHeader";
import "../Adminuser.css";

import {
  Calendar,
  CaretLeft,
  CaretRight,
  Envelope,
  Lock,
  MagnifyingGlass,
  Phone,
  Power,
  UserCircle,
  X,
} from "phosphor-react";
import { Button, Form, Modal } from "react-bootstrap";
import ViewModal from "./ViewModal";
import EditModal from "./EditModal";
import swal from "sweetalert";
import { Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import add from "../../../assets/img/add.svg";
import userDemo from "../../../assets/img/userdemo.svg";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { isStaff, logout_func3 } from "../../Const/logoutCommon";
import Table404 from "../../Const/Table404";
import PmLoader from "../../CommonDesgin/PmLoader";
import PagginationForAll from "../../CommonDesgin/PagginationForAll";
import AddUser from "./AddUser";
import { deleteMultiUser } from "../../Const/MultiDelete";
import PagginationOld from "../../CommonDesgin/PagginationOld";

const ActiveUser = () => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [show2, setShow2] = useState(false);
  const [show4, setShow4] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  const [ids, setIds] = useState([]);
  const [date, setDate] = useState("");
  const handleClose = () => {
    setSingleUser({});
    setDate("");
    setShow(false);
  };
  const handleShow = (data, date) => {
    setSingleUser(data);
    setDate(date);
    setShow(true);
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (data) => {
    setSingleUser(data);
    setShow4(true);
  };
  const handleCheckboxClick = (id) => {
    if (ids.includes(id)) {
      // If ID is already in the array, remove it
      setIds(ids.filter((existingId) => existingId !== id));
    } else {
      // If ID is not in the array, add it
      setIds([...ids, id]);
    }
  };

  const CommonData = {
    title: "Active User",
    path: "Dashboard/ User/ Active User",
    add: true,
    btnData: "Add New User",
    search: true,
  };
  const history = useHistory();
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl2(null);
  };
  //clean up testing
  const [activeUser, setActiveUser] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [total_item, setTotal_item] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 15;
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setActiveUser(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };

  useEffect(() => {
    // apiFunction(`${BASE_URL}v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`)
    apiFunction(
      `${BASE_URL}api/v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}&search=${search}`
    );
  }, [pageNumber, search]);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  const deleteUser = (id) => {
    if (!isStaff) {
      swal("Sorry! You are not an Admin!", {
        icon: "error",
      });
    } else {
      swal({
        title: `Are you sure?`,
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const url = `${BASE_URL}api/v1/auth/user/${id}`;
          axios
            .delete(url)
            .then(() => {
              // showToast("Delele", "Data Deleted");
              // getProduct();
              setSearch("");
              apiFunction(
                `${BASE_URL}api/v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`
              );
              swal("Poof! Your imaginary file has been deleted!", {
                icon: "success",
              });
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              console.log(message);
            });
        } else {
          swal("Your imaginary file is safe!");
        }
      });
    }
  };
  const deleteUserMulti = () => {
    if (!isStaff) {
      swal("Sorry! You are not an Admin!", {
        icon: "error",
      });
    } else {
      swal({
        title: `Are you sure?`,
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          // Initialize an array to store delete promises
          let deletePromises = [];

          // Loop over each id and delete it
          for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            const url = `${BASE_URL}api/v1/auth/user/${id}`;

            // Add each delete request promise to the array
            deletePromises.push(
              axios.delete(url).catch((err) => {
                // Handle individual errors
                const message = err.response
                  ? err.response.data.message
                  : err.message;
                console.error(`Error deleting user ${id}: ${message}`);
              })
            );
          }

          // After all delete promises are completed, run the following
          Promise.all(deletePromises)
            .then(() => {
              // After all deletions have been attempted
              swal("The selected users have been deleted!", {
                icon: "success",
              });

              // Clear selected IDs and search, then refresh the user list
              setIds([]);
              setSearch("");
              apiFunction(
                `${BASE_URL}api/v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`
              );
              console.log("All deletions attempted.");
            })
            .catch((err) => {
              // This will catch if Promise.all itself fails
              console.error("Error in deletion process: ", err);
            });
        } else {
          swal("Your selected users are safe!");
        }
      });
    }
  };
  // Handler to add or remove all IDs
  const handleSelectAllClick = () => {
    if (activeUser.length === ids.length) {
      // If all IDs are already selected, deselect all
      setIds([]);
    } else {
      // Otherwise, select all IDs
      setIds(activeUser.map((data) => data.id));
    }
  };

  // Check if all IDs are selected
  const areAllSelected =
    activeUser.length > 0 && activeUser.every((data) => ids.includes(data.id));

  if (loading) {
    return <PmLoader />;
  }
  console.log(pageCount,'pageCount');
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <div>
        <div className="upper">
          <div>
            <h5>{CommonData?.title}</h5>
            <p>{CommonData?.path}</p>
          </div>
          <div
            className="upper_user"
            aria-controls={open2 ? "long-menu" : undefined}
            aria-expanded={open2 ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick2}
          >
            <img src={userProfile ? userProfile?.image_url : userDemo} alt="" />
          </div>
        </div>
        <>
          <Menu
            id="long-menu"
            className="pop_over3"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose3}
            PaperProps={{
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                width: "22ch",
                marginRight: "10ch",
                top: "89px !important",
                padding: "5px ",
              },
            }}
          >
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                history.push("/view-profile");
                handleClose3();
              }}
            >
              {" "}
              <UserCircle
                color="#1F5DA0"
                size={24}
                weight="bold"
                className="me-3"
              />
              View Profile
            </MenuItem>
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                history.push("/change-password");
                handleClose3();
              }}
            >
              {" "}
              <Lock color="#1F5DA0" size={24} weight="bold" className="me-3" />
              Change Password
            </MenuItem>
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                logout_func3(history);
                handleClose3();
              }}
            >
              {" "}
              <Power color="#1F5DA0" size={24} weight="bold" className="me-3" />
              Logout
            </MenuItem>
          </Menu>
        </>
        <div className="lower mt-4">
          {CommonData.search === true && (
            <div className="input">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search ..."
              />
              <button>
                <MagnifyingGlass color="white" size={28} weight="bold" />
              </button>
            </div>
          )}

          {CommonData.add === true && (
            <div>
              <button onClick={handleShow2} className="d-flex add-user-btn">
                <img className="icon" src={add} alt="" />
                <span className="ms-2">{CommonData.btnData}</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {ids?.length > 0 && (
        <div className="mt-1">
          <button onClick={deleteUserMulti} className="d-flex add-user-btn">
            Delete
          </button>
        </div>
      )}

      <div className="mt-3">
        <div class="table-responsive">
          <table class="table">
            <thead
              style={{
                backgroundColor: "#1f5da0",
                color: "white",
                fontSize: "18px",
              }}
            >
              <tr className="active_tr">
                <th>
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                    color="default"
                    onChange={handleSelectAllClick}
                    checked={areAllSelected}
                  />
                </th>
                <th>PROFILE PICTURE</th>
                <th>FULL NAME</th>
                <th>PHONE NUMBER</th>
                <th>EMAIL</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {activeUser.length > 0 && total_item ? (
                activeUser?.map((data, index) => (
                  <tr
                    key={index}
                    className={`active_tr ${
                      index % 2 === 0 ? "bg-even" : "bg-odd"
                    }`}
                  >
                    <td>
                      <Checkbox
                        sx={{
                          color: "gray",
                          "&.Mui-checked": {
                            color: "gray",
                          },
                        }}
                        color="default"
                        onClick={() => handleCheckboxClick(data?.id)}
                        checked={ids?.includes(data?.id)}
                      />
                    </td>
                    <td>
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={data.image_url}
                        alt=""
                      />
                    </td>
                    <td>
                      {data.first_name} {data?.last_name}
                    </td>
                    <td>{data.mobile}</td>
                    <td>{data.email}</td>
                    <td>
                      <div className="d-flex justify-content-center gap-2">
                        <div
                          className="edit-del blue"
                          onClick={() => handleShow(data, data?.created_at)}
                          style={{
                            background: "#A0A0A0",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <RemoveRedEyeOutlined
                            style={{ color: "white" }}
                            className="icon"
                          />
                        </div>

                        <div
                          onClick={() => handleShow4(data)}
                          className="edit-del green"
                          style={{
                            background: "#418BFB",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <BiPencil color="white" className="icon" />
                        </div>
                        <div
                          className="edit-del red"
                          style={{
                            background: "#FE2C41",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          }}
                          onClick={() => deleteUser(data.id)}
                        >
                          <TbTrash
                            className="icon"
                            style={{ color: "white" }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <Table404 />
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* ==========paggination======= */}
      {total_item !== 0 && (
        <>
          {pageCount < 5 ? (
            <PagginationOld
              prevUrl={prevUrl}
              nextUrl={nextUrl}
              pageCount={pageCount}
              pageNumber={pageNumber}
              handlePaginationNext={handlePaginationNext}
              handlePaginationPrevious={handlePaginationPrevious}
              handleChangeCurrentPage={handleChangeCurrentPage}
              total_item={total_item}
              page_show_start={page_show_start}
              this_page_items_show={this_page_items_show}
            />
          ) : (
            <PagginationForAll
              prevUrl={prevUrl}
              nextUrl={nextUrl}
              pageCount={pageCount}
              pageNumber={pageNumber}
              handlePaginationNext={handlePaginationNext}
              handlePaginationPrevious={handlePaginationPrevious}
              handleChangeCurrentPage={handleChangeCurrentPage}
              total_item={total_item}
              page_show_start={page_show_start}
              this_page_items_show={this_page_items_show}
            />
          )}
        </>
      )}

      {/* ==========paggination======= */}
      <>
        <ViewModal
          singleUser={singleUser}
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          code={
            <>
              <div className="modal_data">
                <div className="d-flex my-2 align-items-center justify-content-center">
                  <Calendar
                    color="#1F5DA0"
                    className="me-2"
                    size={28}
                    weight="bold"
                  />
                  <span>Created At: {date?.split("T")[0]}</span>
                </div>
              </div>
            </>
          }
        />
        <AddUser
          apiFunction={apiFunction}
          show2={show2}
          setShow2={setShow2}
          handleClose2={handleClose2}
        />
        <EditModal
          apiFunction={() =>
            apiFunction(
              `${BASE_URL}api/v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`
            )
          }
          singleUser={singleUser}
          show4={show4}
          setShow4={setShow4}
          handleClose4={handleClose4}
        />
      </>
    </div>
  );
};

export default ActiveUser;
