import { CaretLeft, CaretRight } from "phosphor-react";
import React from "react";

const PaginationForAll = ({
  prevUrl,
  nextUrl,
  pageCount,
  pageNumber,
  handlePaginationNext,
  handlePaginationPrevious,
  handleChangeCurrentPage,
  total_item,
  page_show_start,
  this_page_items_show,
}) => {
  const renderPageNumbers = () => {
    const pages = [];

    // Always show the first two pages
    pages.push(1, 2);

    if (pageNumber > 4) {
      // Add ellipsis if the active page is far from the first two pages
      pages.push("...");
    }

    // Always show the active page
    if (pageNumber > 2 && pageNumber < pageCount - 1) {
      pages.push(pageNumber);
    }

    if (pageNumber < pageCount - 3) {
      // Add ellipsis if the active page is far from the last two pages
      pages.push("...");
    }

    // Always show the last two pages
    pages.push(pageCount - 1, pageCount);

    // Remove duplicates
    return [...new Set(pages)];
  };

  return (
    <div className="mt-4 d-flex justify-content-between align-items-center">
      <div style={{ color: "#1F5DA0", fontSize: "14px" }}>
        <span style={{ fontWeight: "bold" }}>
          {page_show_start} - {this_page_items_show}
        </span>
        <span> Of {total_item}</span>
      </div>
      <div className="d-flex paggi">
        {pageNumber > 1 ? (
          <div
            className="px-2 s-btn pointer"
            onClick={handlePaginationPrevious}
          >
            <CaretLeft size={21} weight="bold" />
          </div>
        ) : (
          <div className="px-2 s-btn">
            <CaretLeft size={21} weight="bold" />
          </div>
        )}
        {renderPageNumbers().map((page, index) => (
          <>
            {page !== 0 && (
              <div
                key={index}
                onClick={() => page !== "..." && handleChangeCurrentPage(page)}
                className={`px-3 s-btn pointer ${
                  page === pageNumber ? "s-active" : ""
                }`}
              >
                {page}
              </div>
            )}
          </>
        ))}
        {pageNumber < pageCount ? (
          <div onClick={handlePaginationNext} className="px-2 s-btn pointer">
            <CaretRight size={21} weight="bold" />
          </div>
        ) : (
          <div className="px-2 s-btn">
            <CaretRight size={21} weight="bold" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginationForAll;
