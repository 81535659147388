import React, { useEffect, useState } from "react";
import PageHeader from "../../CommonDesgin/PageHeader";
import user1 from "../../../assets/img/1.svg";
import user2 from "../../../assets/img/2.svg";
import user3 from "../../../assets/img/3.svg";
import { CaretLeft, CaretRight, Check, X } from "phosphor-react";
import { TbTrash } from "react-icons/tb";
import { BiPencil } from "react-icons/bi";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { Checkbox } from "@mui/material";
import PmLoader from "../../CommonDesgin/PmLoader";
import { BASE_URL } from "../../Const/Url";
import { isStaff, logout_func3 } from "../../Const/logoutCommon";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Table404 from "../../Const/Table404";
import PagginationForAll from "../../CommonDesgin/PagginationForAll";
import swal from "sweetalert";
import PageHead2 from "../../CommonDesgin/PageHead2";
import PagginationOld from "../../CommonDesgin/PagginationOld";

const ApproveUser = () => {
  const [ids, setIds] = useState([]);
  const CommonData = {
    title: "Approve User",
    path: "Dashboard/ User/ Approve User",
    add: false,
    btnData: "",
    search: true,
  };

  //clean up testing
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [activeUser, setActiveUser] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [total_item, setTotal_item] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 15;
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const handleCheckboxClick = (id) => {
    if (ids.includes(id)) {
      // If ID is already in the array, remove it
      setIds(ids.filter((existingId) => existingId !== id));
    } else {
      // If ID is not in the array, add it
      setIds([...ids, id]);
    }
  };

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setActiveUser(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };

  useEffect(() => {
    // apiFunction(`${BASE_URL}v1/auth/user/?is_approve=true&limit=${limit}&offset=${offset}`)
    apiFunction(
      `${BASE_URL}api/v1/auth/user/?is_approve=true&limit=${limit}&offset=${offset}&search=${search}`
    );
  }, [pageNumber, search]);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  const deleteUser = (id) => {
    swal({
      title: `Are you sure?`,
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/auth/user/${id}`;
        axios
          .delete(url)
          .then(() => {
            // showToast("Delele", "Data Deleted");
            // getProduct();
            setSearch("");
            apiFunction(
              `${BASE_URL}api/v1/auth/user/?is_approve=true&limit=${limit}&offset=${offset}`
            );
            swal("Poof! Your imaginary file has been deleted!", {
              icon: "success",
            });
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };
  const approveUser = (id) => {
    swal({
      title: `Are you sure?`,
      text: "Once Click, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/auth/user/${id}/approve/`;
        axios
          .post(url, {
            is_active: false,
          })
          .then(() => {
            apiFunction(
              `${BASE_URL}api/v1/auth/user/?is_approve=true&limit=${limit}&offset=${offset}`
            );
            swal("Poof! User has been Deactivated!", {
              icon: "success",
            });
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };
  const approveUserMulti = () => {
    swal({
      title: `Are you sure?`,
      text: "Once approved, these users will be Deactivated!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willApprove) => {
      if (willApprove) {
        // Initialize an array to store approve promises
        let approvePromises = [];

        // Loop over each id and send approve request
        for (let index = 0; index < ids.length; index++) {
          const id = ids[index];
          const url = `${BASE_URL}api/v1/auth/user/${id}/approve/`;

          // Add each approve request promise to the array
          approvePromises.push(
            axios
              .post(url, {
                is_active: false,
              })
              .catch((err) => {
                // Handle individual errors
                const message = err.response
                  ? err.response.data.message
                  : err.message;
                console.error(`Error approving user ${id}: ${message}`);
              })
          );
        }

        // After all approve promises are completed, run the following
        Promise.all(approvePromises)
          .then(() => {
            // After all approvals have been attempted
            swal("The selected users have been Deactivated!", {
              icon: "success",
            });

            // Clear selected IDs and search, then refresh the user list
            setIds([]);
            setSearch("");
            apiFunction(
              `${BASE_URL}api/v1/auth/user/?is_approve=true&limit=${limit}&offset=${offset}`
            );
            console.log("All approvals attempted.");
          })
          .catch((err) => {
            // This will catch if Promise.all itself fails
            console.error("Error in approval process: ", err);
          });
      } else {
        swal("The selected users remain Approved.");
      }
    });
  };
  const deleteUserMulti = () => {
    if (!isStaff) {
      swal("Sorry! You are not an Admin!", {
        icon: "error",
      });
    } else {
      swal({
        title: `Are you sure?`,
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          // Initialize an array to store delete promises
          let deletePromises = [];

          // Loop over each id and delete it
          for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            const url = `${BASE_URL}api/v1/auth/user/${id}`;

            // Add each delete request promise to the array
            deletePromises.push(
              axios.delete(url).catch((err) => {
                // Handle individual errors
                const message = err.response
                  ? err.response.data.message
                  : err.message;
                console.error(`Error deleting user ${id}: ${message}`);
              })
            );
          }

          // After all delete promises are completed, run the following
          Promise.all(deletePromises)
            .then(() => {
              // After all deletions have been attempted
              swal("The selected users have been deleted!", {
                icon: "success",
              });

              // Clear selected IDs and search, then refresh the user list
              setIds([]);
              setSearch("");
              apiFunction(
                `${BASE_URL}api/v1/auth/user/?is_approve=true&limit=${limit}&offset=${offset}`
              );
              console.log("All deletions attempted.");
            })
            .catch((err) => {
              // This will catch if Promise.all itself fails
              console.error("Error in deletion process: ", err);
            });
        } else {
          swal("Your selected users are safe!");
        }
      });
    }
  };
  // Handler to add or remove all IDs
  const handleSelectAllClick = () => {
    if (activeUser.length === ids.length) {
      // If all IDs are already selected, deselect all
      setIds([]);
    } else {
      // Otherwise, select all IDs
      setIds(activeUser.map((data) => data.id));
    }
  };

  // Check if all IDs are selected
  const areAllSelected =
    activeUser.length > 0 && activeUser.every((data) => ids.includes(data.id));

  if (loading) {
    return <PmLoader />;
  }
  console.log(pageCount, "pageCount");
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <PageHead2
        CommonData={CommonData}
        search={search}
        setSearch={setSearch}
      />
      {ids?.length > 0 && (
        <div className="mt-1 d-flex gap-1">
          <button onClick={deleteUserMulti} className="d-flex add-user-btn">
            Delete
          </button>
          <button onClick={approveUserMulti} className="d-flex add-user-btn">
            Disapprove
          </button>
        </div>
      )}
      <div className="mt-3">
        <div class="table-responsive">
          <table class="table">
            <thead
              style={{
                backgroundColor: "#1f5da0",
                color: "white",
                fontSize: "18px",
              }}
            >
              <tr className="active_tr">
                <th>
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                    color="default"
                    onChange={handleSelectAllClick}
                    checked={areAllSelected}
                  />
                </th>
                <th>PROFILE PICTURE</th>
                <th>FULL NAME</th>
                <th>PHONE NUMBER</th>
                <th>EMAIL</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {activeUser.length > 0 && total_item ? (
                activeUser?.map((data, index) => (
                  <tr
                    key={index}
                    className={`active_tr ${
                      index % 2 === 0 ? "bg-even" : "bg-odd"
                    }`}
                  >
                    <td>
                      <Checkbox
                        sx={{
                          color: "gray",
                          "&.Mui-checked": {
                            color: "gray",
                          },
                        }}
                        color="default"
                        onClick={() => handleCheckboxClick(data?.id)}
                        checked={ids?.includes(data?.id)}
                      />
                    </td>
                    <td>
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={data.image_url}
                        alt=""
                      />
                    </td>
                    <td>
                      {data.first_name} {data?.last_name}
                    </td>
                    <td>{data.mobile}</td>
                    <td>{data.email}</td>
                    <td>
                      <div className="d-flex justify-content-center gap-2">
                        <div
                          onClick={() => {
                            approveUser(data.id);
                          }}
                          className="edit-del red"
                          style={{
                            background: "#FE2C41",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <X className="icon" style={{ color: "white" }} />
                        </div>

                        <div
                          onClick={() => deleteUser(data.id)}
                          className="edit-del red"
                          style={{
                            background: "#FE2C41",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <TbTrash
                            className="icon"
                            style={{ color: "white" }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <Table404 />
              )}
            </tbody>
          </table>
        </div>
      </div>
      {total_item !== 0 && (
        <>
          {pageCount < 5 ? (
            <PagginationOld
              prevUrl={prevUrl}
              nextUrl={nextUrl}
              pageCount={pageCount}
              pageNumber={pageNumber}
              handlePaginationNext={handlePaginationNext}
              handlePaginationPrevious={handlePaginationPrevious}
              handleChangeCurrentPage={handleChangeCurrentPage}
              total_item={total_item}
              page_show_start={page_show_start}
              this_page_items_show={this_page_items_show}
            />
          ) : (
            <PagginationForAll
              prevUrl={prevUrl}
              nextUrl={nextUrl}
              pageCount={pageCount}
              pageNumber={pageNumber}
              handlePaginationNext={handlePaginationNext}
              handlePaginationPrevious={handlePaginationPrevious}
              handleChangeCurrentPage={handleChangeCurrentPage}
              total_item={total_item}
              page_show_start={page_show_start}
              this_page_items_show={this_page_items_show}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ApproveUser;
